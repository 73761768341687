import { Injectable } from '@angular/core';
import { StorageService } from '@app/services/storage/storage.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/store';
import { pensActions } from '@app/store/pens/pens.actions';


@Injectable({
    providedIn: 'root',
})
export class PenService {

    constructor(private storageService: StorageService, private store: Store<AppState>) {
    }


    public async getAllPenIdentities(blnNumber) {
        const oneDayAgo = new Date().getTime();
        const testTimestamp = await this.getFetchPensDate();
        let timeStamp = null;
        if (testTimestamp) {
            timeStamp = testTimestamp.getTime() + (1 * 24 * 60 * 60 * 1000);
            if (oneDayAgo > timeStamp) {
                this.store.dispatch(pensActions.getPens({blnNumber}));
            } else {
                this.store.dispatch(pensActions.getLocalPens());
            }
        }
        else {
            this.store.dispatch(pensActions.getPens({blnNumber}));
        }
    }

    setPenList(blnNumber: any, pens: any) {
        this.storageService.pens.remove(blnNumber).then((resp) => resp);
        this.storageService.pens.set(blnNumber, pens).then((resp) => resp);
    }

    public getPensList = (blnNumber) => this.storageService.pens.get(blnNumber);

    public setFetchPensDate = () => {
        this.storageService.pensFetchDate.set().then((resp) => resp);
    };

    public getFetchPensDate = () => this.storageService.pensFetchDate.get();
}

